<template>
  <div class="px-3">
    <div class="py-4">
      <div class="lg:flex lg:justify-between">
        <h1 class="text-2xl lg:text-4xl font-bold text-white">All Policies</h1>
        <div class="flex gap-3 items-center mt-4 lg:mt-0">
          <div>
            <input type="text" v-model="text" placeholder="Search" class="p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500">
          </div>
          <div class="rounded flex items-center bg-white">
            <svg v-if="filter == ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.4472 20.9051C13.786 20.7376 14 20.3951 14 20.0204V14.6999C14 14.4376 14.1054 14.186 14.2929 14.0005L20.414 7.94576C20.789 7.57483 21 7.07135 21 6.54611V3.98915C21 3.44215 20.553 3 20 3H4C3.447 3 3 3.44215 3 3.98915V6.54611C3 7.07135 3.211 7.57483 3.586 7.94576L9.70711 14.0005C9.89464 14.186 10 14.4376 10 14.6999V21.0096C10 21.7449 10.7823 22.2231 11.4472 21.8943L13.4472 20.9051Z" fill="#2E3A59"></path>
            </svg>
            <svg v-else @click="filter = ''" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.41421 0.414185L7 1.8284L8.1716 3L4 3C3.447 3 3 3.44215 3 3.98915V6.54611C3 7.07135 3.211 7.57483 3.586 7.94576L9.70711 14.0005C9.89464 14.186 10 14.4376 10 14.6999L10 21.0096C10 21.7449 10.7823 22.2231 11.4472 21.8943L13.4472 20.9051C13.786 20.7376 14 20.3951 14 20.0204V14.6999C14 14.4376 14.1054 14.186 14.2929 14.0005L16.7458 11.5742L19.7279 14.5563L21.1421 13.1421L8.41421 0.414185Z" fill="#FF0000"></path>
              <path d="M20.414 7.94576L19.5896 8.7612L13.8284 3L20 3C20.553 3 21 3.44215 21 3.98916V6.54611C21 7.07135 20.789 7.57483 20.414 7.94576Z" fill="#FF0000"></path>
            </svg>
            <select v-model="filter" class="rounded py-2 focus:outline-none" @change="filterBy">
              <option value="" disabled>Filter by</option>
              <option value="bronze">Bronze</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
            </select>
          </div>
          <export-excel
            :data   = "enrollees"
            :fields = "json_fields"
            worksheet = "My Worksheet"
            name = "customers.xls"
            class="cursor-pointer flex items-center rounded py-2 bg-white text-sm px-1"
            >
            <svg class="mr-1" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0021 10.0575C14.0021 10.2801 13.9137 10.4935 13.7563 10.6509C13.5989 10.8083 13.3854 10.8967 13.1628 10.8967C12.9403 10.8967 12.7268 10.8083 12.5694 10.6509C12.412 10.4935 12.3236 10.2801 12.3236 10.0575V5.35766L3.42163 14.2563C3.34426 14.3336 3.25242 14.3949 3.15136 14.4367C3.05029 14.4785 2.94198 14.5 2.8326 14.5C2.72322 14.5 2.61492 14.4784 2.51389 14.4365C2.41285 14.3946 2.32105 14.3332 2.24374 14.2558C2.16643 14.1785 2.10511 14.0866 2.06329 13.9856C2.02147 13.8845 1.99996 13.7762 2 13.6668C2.00004 13.5574 2.02162 13.4491 2.06351 13.3481C2.10541 13.2471 2.16679 13.1553 2.24416 13.0779L11.1444 4.17935H6.44462C6.22193 4.17935 6.00835 4.09088 5.85088 3.93341C5.69341 3.77594 5.60495 3.56237 5.60495 3.33967C5.60495 3.11698 5.69341 2.9034 5.85088 2.74593C6.00835 2.58847 6.22193 2.5 6.44462 2.5H13.1754C13.2563 2.50104 13.3366 2.51376 13.4138 2.53777C13.5846 2.5899 13.7341 2.69569 13.84 2.83945C13.946 2.98322 14.0028 3.1573 14.0021 3.3359V10.0575Z" fill="#2E3A59"/>
            </svg>
            Export
          </export-excel>
          <!-- <a :href="csvCode" 
            download="allpolicies.csv"
            target="_blank" class="cursor-pointer text-white text-sm flex items-center border rounded px-2 py-1" style="box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12); border-color: #2b3553"
          >
            <svg class="mr-1" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0021 10.0575C14.0021 10.2801 13.9137 10.4935 13.7563 10.6509C13.5989 10.8083 13.3854 10.8967 13.1628 10.8967C12.9403 10.8967 12.7268 10.8083 12.5694 10.6509C12.412 10.4935 12.3236 10.2801 12.3236 10.0575V5.35766L3.42163 14.2563C3.34426 14.3336 3.25242 14.3949 3.15136 14.4367C3.05029 14.4785 2.94198 14.5 2.8326 14.5C2.72322 14.5 2.61492 14.4784 2.51389 14.4365C2.41285 14.3946 2.32105 14.3332 2.24374 14.2558C2.16643 14.1785 2.10511 14.0866 2.06329 13.9856C2.02147 13.8845 1.99996 13.7762 2 13.6668C2.00004 13.5574 2.02162 13.4491 2.06351 13.3481C2.10541 13.2471 2.16679 13.1553 2.24416 13.0779L11.1444 4.17935H6.44462C6.22193 4.17935 6.00835 4.09088 5.85088 3.93341C5.69341 3.77594 5.60495 3.56237 5.60495 3.33967C5.60495 3.11698 5.69341 2.9034 5.85088 2.74593C6.00835 2.58847 6.22193 2.5 6.44462 2.5H13.1754C13.2563 2.50104 13.3366 2.51376 13.4138 2.53777C13.5846 2.5899 13.7341 2.69569 13.84 2.83945C13.946 2.98322 14.0028 3.1573 14.0021 3.3359V10.0575Z" fill="#fff"/>
            </svg>
            Export
          </a> -->
        </div>
      </div>
      <div class="mt-4">
        <All :enrollees="enrollees" :loading="loading" v-on:cancel="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import All from "@/components/AllPolicies"
import axios from 'axios'
import baseURL from "@/main"
export default {
  components: {All},
  data(){
    return {
      enrollees: [],
      initialEnrollees: [],
      text: '',
      loading: false,
      filter: "",
      json_fields: {
        'Customer Name': 'policy.name',
        'Email' : 'policy.email',
        'Phone Number' : 'policy.phone',
        'Policy Number': 'policy.policy_number',
        'Plan Type': 'policy.plan',
        'Start Date': 'policy.start',
        'End Date': 'policy.end',
        'Status': 'policy.status',
        'Amount' : 'policy.amount'
      },
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },
  watch:{
    text(){
      if(this.text.length > 0){
        this.enrollees = this.enrollees.filter(obj => obj.policy.name.toLowerCase().indexOf(this.text.toLowerCase()) !== -1 )
      }else{
        this.enrollees = this.initialEnrollees
      }
    },
    filter(){
      if(this.filter == ''){
        this.enrollees = this.initialEnrollees
      }
    }
  },
  computed: {
    csvContent () {
      return this.enrollees.map(item => Object.values(item).join(',')).join('\n')
    },
    csvCode () {
      return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvContent)
    }
  },
  methods:{
    filterBy(){
      if(this.filter !== ''){
        this.enrollees = this.initialEnrollees.filter(obj => obj.policy.plan.toLowerCase() == this.filter)
      }else{
        this.enrollees = this.initialEnrollees
      }
    },
    getPolicies(){
      this.loading = true
      axios.get(`${baseURL}/policy`)
      .then((res)=>{
        console.log(res.data.data)
        this.enrollees = res.data.data
        this.initialEnrollees = res.data.data
        this.loading = false
      })
      .catch((err)=>{
        this.$store.dispatch('handleError', err)
      })
    },
    refresh(){
      this.getPolicies()
    }
  },
  mounted(){
    this.getPolicies()
  },
  created(){
    if(this.$store.state.showMobileNav == true){
      this.$store.commit('showMobileNav', false)
    }
  }
}
</script>

<style>

</style>