<template>
<div>
  <skeleton v-if="loading"></skeleton>
  <div v-else class="overflow-x-auto text-white tablecont">
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Policy Number</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Policy Type</th>
          <th>View</th>
          <th>Cancel</th>
        </tr>
      </thead>
      <tr v-for="(enrollee, index) in paginatedEnrollees" :key="index">
        <td>
          {{index + 1}}
        </td>
        <td class="text-sm">
          {{enrollee.policy.name}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.email}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.phone}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.policy_number}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.start}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.end}}
        </td>
        <td class="text-sm" style="color: #fff;">
          {{enrollee.policy.status}}
        </td>
        <td class="text-sm">
          {{enrollee.policy.plan}}
        </td>
        <td class="text-sm">
          <button class="focus:outline-none py-2 px-2 bg-blue-500 rounded" @click="viewRepayment(enrollee)">View</button>
        </td>
        <td class="text-sm">
          <button class="focus:outline-none py-2 px-2 bg-red-500 rounded" @click="cancelPolicy(enrollee)">Cancel</button>
        </td>
      </tr>
    </table>
    <div class="mt-4 flex justify-between items-center pr-3 pb-6">
      <p><span class="font-semibold">{{this.enrollees.length}} </span>results</p>
      <div v-if="enrollees.length > 0" class="flex gap-4">
        <button @click="page--" :disabled="page == 1" class="rounded text-xs px-2 py-1" :class="[page == 1 ? 'opacity-10' : '']" style="box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);">
          Previous
        </button>
        <button class="rounded text-xs px-2 py-1" :disabled="page == pages.length" @click="page++" :class="[page == pages.length ? 'opacity-10' : '']" style="box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);">
          Next
        </button>
      </div>
    </div>
  </div>
  <Repayments v-if="showRepayment" :policy="policy" @close="showRepayment = false"/>
</div>
</template>

<script>
import axios from 'axios'
import baseURL from "@/main"
import Skeleton from "@/components/comps/PoliciesSkeleton"
import Repayments from "@/components/comps/Repayments"
export default {
  components: {Skeleton,  Repayments},
  props: ['enrollees', 'loading'],
  data(){
    return {
      page: 1,
			perPage: 10,
			pages: [],
      showRepayment: false,
      policy: {}
    }
  },
  watch:{
    enrollees(){
      this.setPages()
    }  
  },
  computed:{
    paginatedEnrollees(){
      return this.paginate(this.enrollees)
    }
  },
  methods:{
    setPages () {
      this.pages = []
			let numberOfPages = Math.ceil(this.enrollees.length / this.perPage);
      if(numberOfPages > 0){
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      }
			
		},
		paginate (enrollees) {
			let page = this.page;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
			return  enrollees.slice(from, to);
		},
    cancelPolicy(obj){
      let data = {
        policy_id: obj.policy.user_home_id
      }
      this.$store.commit('startLoading')
      axios({url: `${baseURL}/policy/cancel`, data: data, method: 'POST'})
      .then((res)=>{
        this.$store.commit('setSuccess', {status: true, msg: res.data.message})
        this.$store.commit('endLoading')
        this.$emit('cancel')
      })
      .catch((err)=>{
        this.$store.dispatch('handleError', err)
      })
    },
    viewRepayment(obj){
      this.policy = obj
      this.showRepayment = true
    }
    // getPolicies(){
    //   axios.get(`${baseURL}/tizeti/policy`)
    //   .then((res)=>{
    //     // console.log(res.data.data)
    //     this.enrollees = res.data.data
    //   })
    //   .catch((err)=>{
    //     this.$store.dispatch('handleError', err)
    //   })
    // }
  },
  // mounted(){
  //   this.getPolicies()
  // }

}
</script>

<style scoped>
table{
  width: 100%
}
th{
  text-align: left;
  border-top: 1px solid rgb(155, 155, 155);
  padding: 10px;
}
td{
  padding: 8px
}
th, td{
  border-bottom: 1px solid rgb(155, 155, 155);
}
.tablecont::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.tablecont::-webkit-scrollbar
{
  margin-top: 20px;
  height: 2px;
  background-color: #F5F5F5;
}

.tablecont::-webkit-scrollbar-thumb
{
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color:#14801B;
}
@media only screen and (min-width: 1024px) {

  thead th:nth-child(1){
    width: 3%;
  }
  thead th:nth-child(2){
    min-width: 170px;
  }
  thead th:nth-child(3){
    min-width: 270px;
  }
  thead th:nth-child(4){
    min-width: 170px;
  }
  thead th:nth-child(5){
    min-width: 170px;
  }
  thead th:nth-child(6){
    min-width: 120px;
  }
  thead th:nth-child(7){
    min-width: 120px;
  }
  thead th:nth-child(8){
    min-width: 120px;
  }
  thead th:nth-child(9){
    min-width: 60px;
  }
  thead th:nth-child(10){
    min-width: 75px;
  }
  thead th:nth-child(11){
    min-width: 75px;
  }
}
</style>